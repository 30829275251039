
      import pug from 'pug-runtime';

      function homeMasterRightcolumn(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (articles, card, endpoint, episode, filter, fluid, getDocumentEndpoint, getMIMEType, intro, kind, media, people, podcast, printDate, resimg, rightColumn, search, serie, slider, toTextOnly, type) {
      pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes(["right-column position-relative flex flex-column flex-grow-1 ps-4" + (!fluid ? " display-alert" : "")], [true]), false, false)) + "\u003E";
if (kind === "MasterDoubleArticles") {
articles = rightColumn.elements;
pug_html = pug_html + "\u003Cdiv class=\"d-flex justify-content-center w-100\"\u003E\u003Cdiv" + (" class=\"btn add-button w-auto border-0\""+" data-bs-toggle=\"modal\" data-bs-target=\"#add-master-element\""+pug.attr("data-lj-endpoint", endpoint ? endpoint : '["/episodes", "/podcasts"]', true, false)+pug.attr("data-lj-filter", filter ? filter : "", true, false)+pug.attr("data-lj-type", type ? type : "Cards", true, false)) + "\u003E\u003Ci class=\"fa-solid fa-circle-plus fa-3x text-primary\"\u003E\u003C\u002Fi\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"right-elements my-2 row flex-column g-3 js-deck\" data-drag-and-drop-container=\"home\" data-multiple-container-length=\"3\"\u003E";
// iterate articles
;(function(){
  var $$obj = articles;
  if ('number' == typeof $$obj.length) {
      for (var index = 0, $$l = $$obj.length; index < $$l; index++) {
        var element = $$obj[index];
pug_html = pug_html + "\u003Cdiv class=\"col\"\u003E";
if (element.model === "Episode") {
episode = element.document;
pug_html = pug_html + "\u003Cdiv class=\"card episode-card card-small position-relative rounded h-100\"\u003E\u003Cinput type=\"hidden\" name=\"model\" value=\"Episode\"\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"document\""+pug.attr("value", episode.id, true, false)) + "\u002F\u003E";
if (serie) {
pug_html = pug_html + "\u003Cinput" + (" type=\"hidden\" name=\"serie\""+pug.attr("value", serie, true, false)) + "\u002F\u003E";
}
if (episode.type !== "sans-image" && episode.image) {
pug_html = pug_html + "\u003Cimg" + (" class=\"card-img-top img-fluid min-height h-100\""+pug.attr("src", resimg(episode.image.files ? episode.image.files[0] : episode.image.img, "840"), true, false)+pug.attr("alt", toTextOnly(episode.image.title), true, false)) + "\u002F\u003E\u003Cdiv class=\"card-img-overlay p-2\"\u003E\u003Cdiv class=\"d-flex justify-content-between align-items-start mb-3\"\u003E\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003Cdiv class=\"btn-group\"\u003E";
if (episode.visibility === "visible") {
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-warning me-2\"\u003Evisible\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-primary\"\u003E#" + (pug.escape(null == (pug_interp = episode.number) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Ca" + (" class=\"link-light\""+pug.attr("href", getDocumentEndpoint(episode), true, false)) + "\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = toTextOnly(episode.title)) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003C\u002Fa\u003E";
if (episode.date) {
pug_html = pug_html + "\u003Cp class=\"card-text text-white\"\u003EPublié le " + (pug.escape(null == (pug_interp = printDate(episode.date)) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
}
else {
pug_html = pug_html + "\u003Cp class=\"card-text text-danger\"\u003ENon publié\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"card-body bg-info rounded min-height h-100\"\u003E\u003Cdiv class=\"d-flex justify-content-between align-items-start mb-3\"\u003E\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003Cdiv class=\"btn-group\"\u003E";
if (episode.visibility === "visible") {
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-warning me-2\"\u003Evisible\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-primary\"\u003E#" + (pug.escape(null == (pug_interp = episode.number) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
if (episode.quote) {
pug_html = pug_html + "\u003Cp class=\"mb-1 fst-italic episode-quote text-white\"\u003E" + (null == (pug_interp = episode.quote.text) ? "" : pug_interp) + "\u003C\u002Fp\u003E\u003Cp class=\"mb-3 text-white\"\u003E" + (pug.escape(null == (pug_interp = episode.quote.author) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003Ca" + (" class=\"link-light\""+pug.attr("href", getDocumentEndpoint(episode), true, false)) + "\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = toTextOnly(episode.title)) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003C\u002Fa\u003E";
if (episode.date) {
pug_html = pug_html + "\u003Cp class=\"card-text text-white\"\u003EPublié le " + (pug.escape(null == (pug_interp = printDate(episode.date)) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
}
else {
pug_html = pug_html + "\u003Cp class=\"card-text text-danger\"\u003ENon publié\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"master-alert w-100 rounded-bottom\"\u003E\u003Csmall class=\"w-100 p-1 bg-danger text-center text-white\"\u003EÉlément masqué sur desktop\u003Cbr \u002F\u003E(limite&nbsp;: 2 éléments)\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"une-alert w-100 rounded-bottom\"\u003E\u003Csmall class=\"w-100 p-1 bg-danger text-center text-white\"\u003EÉlément non visible (limite&nbsp;: 12 éléments)\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else
if (element.model === "Podcast") {
podcast = element.document;
pug_html = pug_html + "\u003Cdiv class=\"card podcast-card card-small position-relative rounded h-100\"\u003E\u003Cinput type=\"hidden\" name=\"model\" value=\"Podcast\"\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"document\""+pug.attr("value", podcast.id, true, false)) + "\u002F\u003E";
if (serie) {
pug_html = pug_html + "\u003Cinput" + (" type=\"hidden\" name=\"serie\""+pug.attr("value", serie, true, false)) + "\u002F\u003E";
}
if (podcast.image) {
pug_html = pug_html + "\u003Cimg" + (" class=\"card-img-top img-fluid min-height h-100\""+pug.attr("src", resimg(podcast.image.files ? podcast.image.files[0] : podcast.image.img, "840"), true, false)+pug.attr("alt", toTextOnly(podcast.title), true, false)) + "\u002F\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"card-body bg-info rounded-top min-height h-100\"\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"card-img-overlay p-2\"\u003E\u003Cdiv class=\"d-flex justify-content-between align-items-start mb-3\"\u003E\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003Cdiv class=\"btn-group\"\u003E";
if (podcast.visibility === "visible") {
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-warning me-2\"\u003Evisible\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-primary\"\u003E#" + (pug.escape(null == (pug_interp = podcast.number) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"d-flex\"\u003E\u003Ci class=\"d-inline fa-regular fa-circle-play fa-2x text-white me-2\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003Ca" + (" class=\"link-light mb-2\""+pug.attr("href", getDocumentEndpoint(podcast), true, false)) + "\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = toTextOnly(podcast.title)) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
if (podcast.date) {
pug_html = pug_html + "\u003Cp class=\"card-text text-white\"\u003EPublié le " + (pug.escape(null == (pug_interp = printDate(podcast.date)) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
}
else {
pug_html = pug_html + "\u003Cp class=\"card-text text-danger\"\u003ENon publié\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"master-alert w-100 rounded-bottom\"\u003E\u003Csmall class=\"w-100 p-1 bg-danger text-center text-white\"\u003EÉlément masqué sur desktop\u003Cbr \u002F\u003E(limite&nbsp;: 2 éléments)\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"une-alert w-100 rounded-bottom\"\u003E\u003Csmall class=\"w-100 p-1 bg-danger text-center text-white\"\u003EÉlément non visible (limite&nbsp;: 12 éléments)\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var index in $$obj) {
      $$l++;
      var element = $$obj[index];
pug_html = pug_html + "\u003Cdiv class=\"col\"\u003E";
if (element.model === "Episode") {
episode = element.document;
pug_html = pug_html + "\u003Cdiv class=\"card episode-card card-small position-relative rounded h-100\"\u003E\u003Cinput type=\"hidden\" name=\"model\" value=\"Episode\"\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"document\""+pug.attr("value", episode.id, true, false)) + "\u002F\u003E";
if (serie) {
pug_html = pug_html + "\u003Cinput" + (" type=\"hidden\" name=\"serie\""+pug.attr("value", serie, true, false)) + "\u002F\u003E";
}
if (episode.type !== "sans-image" && episode.image) {
pug_html = pug_html + "\u003Cimg" + (" class=\"card-img-top img-fluid min-height h-100\""+pug.attr("src", resimg(episode.image.files ? episode.image.files[0] : episode.image.img, "840"), true, false)+pug.attr("alt", toTextOnly(episode.image.title), true, false)) + "\u002F\u003E\u003Cdiv class=\"card-img-overlay p-2\"\u003E\u003Cdiv class=\"d-flex justify-content-between align-items-start mb-3\"\u003E\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003Cdiv class=\"btn-group\"\u003E";
if (episode.visibility === "visible") {
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-warning me-2\"\u003Evisible\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-primary\"\u003E#" + (pug.escape(null == (pug_interp = episode.number) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Ca" + (" class=\"link-light\""+pug.attr("href", getDocumentEndpoint(episode), true, false)) + "\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = toTextOnly(episode.title)) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003C\u002Fa\u003E";
if (episode.date) {
pug_html = pug_html + "\u003Cp class=\"card-text text-white\"\u003EPublié le " + (pug.escape(null == (pug_interp = printDate(episode.date)) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
}
else {
pug_html = pug_html + "\u003Cp class=\"card-text text-danger\"\u003ENon publié\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"card-body bg-info rounded min-height h-100\"\u003E\u003Cdiv class=\"d-flex justify-content-between align-items-start mb-3\"\u003E\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003Cdiv class=\"btn-group\"\u003E";
if (episode.visibility === "visible") {
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-warning me-2\"\u003Evisible\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-primary\"\u003E#" + (pug.escape(null == (pug_interp = episode.number) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
if (episode.quote) {
pug_html = pug_html + "\u003Cp class=\"mb-1 fst-italic episode-quote text-white\"\u003E" + (null == (pug_interp = episode.quote.text) ? "" : pug_interp) + "\u003C\u002Fp\u003E\u003Cp class=\"mb-3 text-white\"\u003E" + (pug.escape(null == (pug_interp = episode.quote.author) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003Ca" + (" class=\"link-light\""+pug.attr("href", getDocumentEndpoint(episode), true, false)) + "\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = toTextOnly(episode.title)) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003C\u002Fa\u003E";
if (episode.date) {
pug_html = pug_html + "\u003Cp class=\"card-text text-white\"\u003EPublié le " + (pug.escape(null == (pug_interp = printDate(episode.date)) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
}
else {
pug_html = pug_html + "\u003Cp class=\"card-text text-danger\"\u003ENon publié\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"master-alert w-100 rounded-bottom\"\u003E\u003Csmall class=\"w-100 p-1 bg-danger text-center text-white\"\u003EÉlément masqué sur desktop\u003Cbr \u002F\u003E(limite&nbsp;: 2 éléments)\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"une-alert w-100 rounded-bottom\"\u003E\u003Csmall class=\"w-100 p-1 bg-danger text-center text-white\"\u003EÉlément non visible (limite&nbsp;: 12 éléments)\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else
if (element.model === "Podcast") {
podcast = element.document;
pug_html = pug_html + "\u003Cdiv class=\"card podcast-card card-small position-relative rounded h-100\"\u003E\u003Cinput type=\"hidden\" name=\"model\" value=\"Podcast\"\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"document\""+pug.attr("value", podcast.id, true, false)) + "\u002F\u003E";
if (serie) {
pug_html = pug_html + "\u003Cinput" + (" type=\"hidden\" name=\"serie\""+pug.attr("value", serie, true, false)) + "\u002F\u003E";
}
if (podcast.image) {
pug_html = pug_html + "\u003Cimg" + (" class=\"card-img-top img-fluid min-height h-100\""+pug.attr("src", resimg(podcast.image.files ? podcast.image.files[0] : podcast.image.img, "840"), true, false)+pug.attr("alt", toTextOnly(podcast.title), true, false)) + "\u002F\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"card-body bg-info rounded-top min-height h-100\"\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"card-img-overlay p-2\"\u003E\u003Cdiv class=\"d-flex justify-content-between align-items-start mb-3\"\u003E\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003Cdiv class=\"btn-group\"\u003E";
if (podcast.visibility === "visible") {
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-warning me-2\"\u003Evisible\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-primary\"\u003E#" + (pug.escape(null == (pug_interp = podcast.number) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"d-flex\"\u003E\u003Ci class=\"d-inline fa-regular fa-circle-play fa-2x text-white me-2\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003Ca" + (" class=\"link-light mb-2\""+pug.attr("href", getDocumentEndpoint(podcast), true, false)) + "\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = toTextOnly(podcast.title)) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
if (podcast.date) {
pug_html = pug_html + "\u003Cp class=\"card-text text-white\"\u003EPublié le " + (pug.escape(null == (pug_interp = printDate(podcast.date)) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
}
else {
pug_html = pug_html + "\u003Cp class=\"card-text text-danger\"\u003ENon publié\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"master-alert w-100 rounded-bottom\"\u003E\u003Csmall class=\"w-100 p-1 bg-danger text-center text-white\"\u003EÉlément masqué sur desktop\u003Cbr \u002F\u003E(limite&nbsp;: 2 éléments)\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"une-alert w-100 rounded-bottom\"\u003E\u003Csmall class=\"w-100 p-1 bg-danger text-center text-white\"\u003EÉlément non visible (limite&nbsp;: 12 éléments)\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
else {
card = rightColumn.card;
intro = rightColumn.intro ? rightColumn.intro : "";
articles = rightColumn.elements;
pug_html = pug_html + "\u003Cdiv class=\"mb-4\"\u003E\u003Cdiv class=\"edith-editor\" data-edith-height=\"70\"\u003E\u003Cinput" + (" type=\"hidden\" name=\"card-intro\""+pug.attr("value", intro, true, false)+" autocomplete=\"off\"") + "\u002F\u003E\u003Cdiv class=\"edith-element\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
media = card;
search = true;
endpoint = kind === "MasterDoubleObsession" ? "/obsessions" : "/programmes";
pug_html = pug_html + "\u003Cdiv class=\"card media-card card-small rounded\"\u003E\u003Cinput" + (" type=\"hidden\" name=\"model\""+pug.attr("value", media.modelName, true, false)) + "\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"document\""+pug.attr("value", media._id, true, false)) + "\u002F\u003E";
if (media.img || (media.modelName === "SlideshowImage") || (media.modelName === "ComparisonImage")) {
pug_html = pug_html + "\u003Cimg" + (" class=\"card-img-top img-fluid rounded-top\""+pug.attr("src", resimg(media.img ? media.img : media.files[0], "840"), true, false)+pug.attr("alt", media.alt, true, false)) + "\u002F\u003E\u003Cdiv class=\"card-img-overlay p-2\"\u003E";
if (search) {
pug_html = pug_html + "\u003Cdiv class=\"search top-0 dropdown w-100\" role=\"group\"\u003E\u003Cdiv class=\"input-group\"\u003E\u003Cinput" + (" class=\"form-control js-autocomplete\""+" type=\"text\" placeholder=\"Modifier le média à la une\""+pug.attr("data-endpoint", endpoint ? endpoint : '["/obsessions", "/programmes"]', true, false)+" autocomplete=\"off\"") + "\u002F\u003E\u003Cspan class=\"input-group-text\"\u003E\u003Ci class=\"fa-solid fa-magnifying-glass\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"dropdown-menu dropdown-menu-w100\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"d-flex justify-content-between align-items-start mb-3\"\u003E";
if (!(slider && slider.obsession)) {
pug_html = pug_html + "\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Ca" + (" class=\"link-light\""+pug.attr("href", getDocumentEndpoint(media), true, false)) + "\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = media.title) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003C\u002Fa\u003E\u003Cp class=\"card-text text-white\"\u003E" + (null == (pug_interp = media.subtitle) ? "" : pug_interp) + "\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E";
}
else
if (media.files) {
pug_html = pug_html + "\u003Cdiv class=\"card-body bg-info\"\u003E";
if (media.modelName === "MusicSoundtrack") {
pug_html = pug_html + "\u003Cfigure class=\"mt-2\"\u003E\u003Caudio class=\"w-100\" controls=\"true\" preload=\"none\"\u003E";
// iterate media.files
;(function(){
  var $$obj = media.files;
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var audio = $$obj[pug_index1];
pug_html = pug_html + "\u003Csource" + (pug.attr("src", audio, true, false)+pug.attr("type", getMIMEType(audio), true, false)) + "\u002F\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var audio = $$obj[pug_index1];
pug_html = pug_html + "\u003Csource" + (pug.attr("src", audio, true, false)+pug.attr("type", getMIMEType(audio), true, false)) + "\u002F\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Faudio\u003E\u003C\u002Ffigure\u003E";
}
else
if (media.modelName === "Document") {
pug_html = pug_html + "\u003Cdiv class=\"mt-2\"\u003E";
// iterate media.files
;(function(){
  var $$obj = media.files;
  if ('number' == typeof $$obj.length) {
      for (var pug_index2 = 0, $$l = $$obj.length; pug_index2 < $$l; pug_index2++) {
        var doc = $$obj[pug_index2];
pug_html = pug_html + "\u003Ca" + (pug.attr("href", doc, true, false)+" target=\"_blank\"") + "\u003E\u003Ci class=\"fa-solid fa-file\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E&nbsp;&nbsp;" + (pug.escape(null == (pug_interp = doc.replace("/ressources/document/", "")) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index2 in $$obj) {
      $$l++;
      var doc = $$obj[pug_index2];
pug_html = pug_html + "\u003Ca" + (pug.attr("href", doc, true, false)+" target=\"_blank\"") + "\u003E\u003Ci class=\"fa-solid fa-file\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E&nbsp;&nbsp;" + (pug.escape(null == (pug_interp = doc.replace("/ressources/document/", "")) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Ca" + (" class=\"link-light\""+pug.attr("href", getDocumentEndpoint(media), true, false)) + "\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = media.title) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003C\u002Fa\u003E\u003Cp class=\"card-text text-white\"\u003E" + (null == (pug_interp = media.subtitle) ? "" : pug_interp) + "\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"right-elements my-2 row flex-column g-3 js-deck js-drag-and-drop-container\" drag-and-drop-direction=\"vertical\"\u003E";
// iterate articles
;(function(){
  var $$obj = articles;
  if ('number' == typeof $$obj.length) {
      for (var index = 0, $$l = $$obj.length; index < $$l; index++) {
        var element = $$obj[index];
pug_html = pug_html + "\u003Cdiv class=\"col\"\u003E";
if (element.model === "Episode") {
episode = element.document;
pug_html = pug_html + "\u003Cdiv class=\"card card-list\"\u003E\u003Cinput type=\"hidden\" name=\"model\" value=\"Episode\"\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"document\""+pug.attr("value", episode.id, true, false)) + "\u002F\u003E\u003Cdiv class=\"d-flex flex-nowrap g-0\"\u003E\u003Cdiv class=\"pt-2 ps-2\"\u003E\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"flex-grow-1\"\u003E\u003Cdiv class=\"card-body\"\u003E\u003Ca" + (" class=\"link-dark\""+pug.attr("href", getDocumentEndpoint(episode), true, false)) + "\u003E\u003Ch6 class=\"card-title\"\u003E" + (null == (pug_interp = toTextOnly(episode.title)) ? "" : pug_interp) + "\u003C\u002Fh6\u003E\u003C\u002Fa\u003E\u003Cp class=\"card-text mb-0\"\u003E\u003C\u002Fp\u003E";
if (episode.date) {
pug_html = pug_html + "\u003Csmall class=\"text-muted\"\u003EPublié le " + (pug.escape(null == (pug_interp = printDate(episode.date)) ? "" : pug_interp)) + "\u003C\u002Fsmall\u003E";
}
else {
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-danger\"\u003ENon publié\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"flex mb-0\"\u003E\u003Cspan class=\"badge rounded-pill bg-primary me-2\"\u003E#" + (pug.escape(null == (pug_interp = episode.number) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
if (episode.visibility === "visible") {
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-warning me-2\"\u003Evisible\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"master-alert w-100 rounded-bottom\"\u003E\u003Csmall class=\"w-100 p-1 bg-danger text-center text-white\"\u003EÉlément masqué sur desktop\u003Cbr \u002F\u003E(limite&nbsp;: 2 éléments)\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else
if (element.model === "People") {
people = element.document;
pug_html = pug_html + "\u003Cdiv class=\"card card-list\"\u003E\u003Cinput type=\"hidden\" name=\"model\" value=\"People\"\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"document\""+pug.attr("value", people.id, true, false)) + "\u002F\u003E\u003Cdiv class=\"row g-0\"\u003E\u003Cdiv class=\"col-md-4\"\u003E\u003Cdiv class=\"card-btn p-1\"\u003E\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003Cimg" + (" class=\"h-100 w-100 rounded-start\""+" style=\"object-fit: cover\""+pug.attr("src", resimg(people.img, "840"), true, false)+pug.attr("alt", people.alt, true, false)) + "\u002F\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-md-8\"\u003E\u003Cdiv class=\"card-body\"\u003E\u003Ca" + (" class=\"link-dark\""+pug.attr("href", getDocumentEndpoint(people), true, false)) + "\u003E\u003Ch6 class=\"card-title\"\u003E" + (null == (pug_interp = people.title) ? "" : pug_interp) + "\u003C\u002Fh6\u003E\u003C\u002Fa\u003E\u003Cp class=\"card-text mb-0\"\u003E\u003Csmall class=\"text-muted\"\u003E" + (null == (pug_interp = people.subtitle) ? "" : pug_interp) + "\u003C\u002Fsmall\u003E\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"master-alert w-100 rounded-bottom\"\u003E\u003Csmall class=\"w-100 p-1 bg-danger text-center text-white\"\u003EÉlément masqué sur desktop\u003Cbr \u002F\u003E(limite&nbsp;: 2 éléments)\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else
if (element.model === "Podcast") {
podcast = element.document;
pug_html = pug_html + "\u003Cdiv class=\"card card-list\"\u003E\u003Cinput type=\"hidden\" name=\"model\" value=\"Podcast\"\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"document\""+pug.attr("value", podcast.id, true, false)) + "\u002F\u003E\u003Cdiv class=\"d-flex flex-nowrap g-0\"\u003E\u003Cdiv class=\"pt-2 ps-2\"\u003E\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"flex-grow-1\"\u003E\u003Cdiv class=\"card-body\"\u003E\u003Cdiv class=\"d-inline-flex align-items-center mb-2\"\u003E\u003Ci class=\"fa-regular fa-circle-play fa-2x text-primary me-2\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003Ca" + (" class=\"link-dark\""+pug.attr("href", getDocumentEndpoint(podcast), true, false)) + "\u003E\u003Ch6 class=\"card-title mb-0\"\u003E" + (null == (pug_interp = toTextOnly(podcast.title)) ? "" : pug_interp) + "\u003C\u002Fh6\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003Cp class=\"card-text mb-0\"\u003E\u003C\u002Fp\u003E";
if (podcast.date) {
pug_html = pug_html + "\u003Csmall class=\"text-muted\"\u003EPublié le " + (pug.escape(null == (pug_interp = printDate(podcast.date)) ? "" : pug_interp)) + "\u003C\u002Fsmall\u003E";
}
else {
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-danger\"\u003ENon publié\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"flex mb-0\"\u003E\u003Cspan class=\"badge rounded-pill bg-primary me-2\"\u003E#" + (pug.escape(null == (pug_interp = podcast.number) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Cspan class=\"badge rounded-pill bg-warning me-2\"\u003Evisible\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"master-alert w-100 rounded-bottom\"\u003E\u003Csmall class=\"w-100 p-1 bg-danger text-center text-white\"\u003EÉlément masqué sur desktop\u003Cbr \u002F\u003E(limite&nbsp;: 2 éléments)\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var index in $$obj) {
      $$l++;
      var element = $$obj[index];
pug_html = pug_html + "\u003Cdiv class=\"col\"\u003E";
if (element.model === "Episode") {
episode = element.document;
pug_html = pug_html + "\u003Cdiv class=\"card card-list\"\u003E\u003Cinput type=\"hidden\" name=\"model\" value=\"Episode\"\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"document\""+pug.attr("value", episode.id, true, false)) + "\u002F\u003E\u003Cdiv class=\"d-flex flex-nowrap g-0\"\u003E\u003Cdiv class=\"pt-2 ps-2\"\u003E\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"flex-grow-1\"\u003E\u003Cdiv class=\"card-body\"\u003E\u003Ca" + (" class=\"link-dark\""+pug.attr("href", getDocumentEndpoint(episode), true, false)) + "\u003E\u003Ch6 class=\"card-title\"\u003E" + (null == (pug_interp = toTextOnly(episode.title)) ? "" : pug_interp) + "\u003C\u002Fh6\u003E\u003C\u002Fa\u003E\u003Cp class=\"card-text mb-0\"\u003E\u003C\u002Fp\u003E";
if (episode.date) {
pug_html = pug_html + "\u003Csmall class=\"text-muted\"\u003EPublié le " + (pug.escape(null == (pug_interp = printDate(episode.date)) ? "" : pug_interp)) + "\u003C\u002Fsmall\u003E";
}
else {
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-danger\"\u003ENon publié\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"flex mb-0\"\u003E\u003Cspan class=\"badge rounded-pill bg-primary me-2\"\u003E#" + (pug.escape(null == (pug_interp = episode.number) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
if (episode.visibility === "visible") {
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-warning me-2\"\u003Evisible\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"master-alert w-100 rounded-bottom\"\u003E\u003Csmall class=\"w-100 p-1 bg-danger text-center text-white\"\u003EÉlément masqué sur desktop\u003Cbr \u002F\u003E(limite&nbsp;: 2 éléments)\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else
if (element.model === "People") {
people = element.document;
pug_html = pug_html + "\u003Cdiv class=\"card card-list\"\u003E\u003Cinput type=\"hidden\" name=\"model\" value=\"People\"\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"document\""+pug.attr("value", people.id, true, false)) + "\u002F\u003E\u003Cdiv class=\"row g-0\"\u003E\u003Cdiv class=\"col-md-4\"\u003E\u003Cdiv class=\"card-btn p-1\"\u003E\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003Cimg" + (" class=\"h-100 w-100 rounded-start\""+" style=\"object-fit: cover\""+pug.attr("src", resimg(people.img, "840"), true, false)+pug.attr("alt", people.alt, true, false)) + "\u002F\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"col-md-8\"\u003E\u003Cdiv class=\"card-body\"\u003E\u003Ca" + (" class=\"link-dark\""+pug.attr("href", getDocumentEndpoint(people), true, false)) + "\u003E\u003Ch6 class=\"card-title\"\u003E" + (null == (pug_interp = people.title) ? "" : pug_interp) + "\u003C\u002Fh6\u003E\u003C\u002Fa\u003E\u003Cp class=\"card-text mb-0\"\u003E\u003Csmall class=\"text-muted\"\u003E" + (null == (pug_interp = people.subtitle) ? "" : pug_interp) + "\u003C\u002Fsmall\u003E\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"master-alert w-100 rounded-bottom\"\u003E\u003Csmall class=\"w-100 p-1 bg-danger text-center text-white\"\u003EÉlément masqué sur desktop\u003Cbr \u002F\u003E(limite&nbsp;: 2 éléments)\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else
if (element.model === "Podcast") {
podcast = element.document;
pug_html = pug_html + "\u003Cdiv class=\"card card-list\"\u003E\u003Cinput type=\"hidden\" name=\"model\" value=\"Podcast\"\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"document\""+pug.attr("value", podcast.id, true, false)) + "\u002F\u003E\u003Cdiv class=\"d-flex flex-nowrap g-0\"\u003E\u003Cdiv class=\"pt-2 ps-2\"\u003E\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"flex-grow-1\"\u003E\u003Cdiv class=\"card-body\"\u003E\u003Cdiv class=\"d-inline-flex align-items-center mb-2\"\u003E\u003Ci class=\"fa-regular fa-circle-play fa-2x text-primary me-2\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003Ca" + (" class=\"link-dark\""+pug.attr("href", getDocumentEndpoint(podcast), true, false)) + "\u003E\u003Ch6 class=\"card-title mb-0\"\u003E" + (null == (pug_interp = toTextOnly(podcast.title)) ? "" : pug_interp) + "\u003C\u002Fh6\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003Cp class=\"card-text mb-0\"\u003E\u003C\u002Fp\u003E";
if (podcast.date) {
pug_html = pug_html + "\u003Csmall class=\"text-muted\"\u003EPublié le " + (pug.escape(null == (pug_interp = printDate(podcast.date)) ? "" : pug_interp)) + "\u003C\u002Fsmall\u003E";
}
else {
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-danger\"\u003ENon publié\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"flex mb-0\"\u003E\u003Cspan class=\"badge rounded-pill bg-primary me-2\"\u003E#" + (pug.escape(null == (pug_interp = podcast.number) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003Cspan class=\"badge rounded-pill bg-warning me-2\"\u003Evisible\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"master-alert w-100 rounded-bottom\"\u003E\u003Csmall class=\"w-100 p-1 bg-danger text-center text-white\"\u003EÉlément masqué sur desktop\u003Cbr \u002F\u003E(limite&nbsp;: 2 éléments)\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"d-flex justify-content-center w-100\"\u003E";
endpoint = kind === "MasterDoubleObsession" ? '["/episodes", "/medias/people"]' : '["/podcasts"]';
type = "Lists";
filter = kind === "MasterDoubleObsession" ? "obsession=" + card._id : "programme=" + card._id;
pug_html = pug_html + "\u003Cdiv" + (" class=\"btn add-button w-auto border-0\""+" data-bs-toggle=\"modal\" data-bs-target=\"#add-master-element\""+pug.attr("data-lj-endpoint", endpoint ? endpoint : '["/episodes", "/podcasts"]', true, false)+pug.attr("data-lj-filter", filter ? filter : "", true, false)+pug.attr("data-lj-type", type ? type : "Cards", true, false)) + "\u003E\u003Ci class=\"fa-solid fa-circle-plus fa-3x text-primary\"\u003E\u003C\u002Fi\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
    }.call(this, "articles" in locals_for_with ?
        locals_for_with.articles :
        typeof articles !== 'undefined' ? articles : undefined, "card" in locals_for_with ?
        locals_for_with.card :
        typeof card !== 'undefined' ? card : undefined, "endpoint" in locals_for_with ?
        locals_for_with.endpoint :
        typeof endpoint !== 'undefined' ? endpoint : undefined, "episode" in locals_for_with ?
        locals_for_with.episode :
        typeof episode !== 'undefined' ? episode : undefined, "filter" in locals_for_with ?
        locals_for_with.filter :
        typeof filter !== 'undefined' ? filter : undefined, "fluid" in locals_for_with ?
        locals_for_with.fluid :
        typeof fluid !== 'undefined' ? fluid : undefined, "getDocumentEndpoint" in locals_for_with ?
        locals_for_with.getDocumentEndpoint :
        typeof getDocumentEndpoint !== 'undefined' ? getDocumentEndpoint : undefined, "getMIMEType" in locals_for_with ?
        locals_for_with.getMIMEType :
        typeof getMIMEType !== 'undefined' ? getMIMEType : undefined, "intro" in locals_for_with ?
        locals_for_with.intro :
        typeof intro !== 'undefined' ? intro : undefined, "kind" in locals_for_with ?
        locals_for_with.kind :
        typeof kind !== 'undefined' ? kind : undefined, "media" in locals_for_with ?
        locals_for_with.media :
        typeof media !== 'undefined' ? media : undefined, "people" in locals_for_with ?
        locals_for_with.people :
        typeof people !== 'undefined' ? people : undefined, "podcast" in locals_for_with ?
        locals_for_with.podcast :
        typeof podcast !== 'undefined' ? podcast : undefined, "printDate" in locals_for_with ?
        locals_for_with.printDate :
        typeof printDate !== 'undefined' ? printDate : undefined, "resimg" in locals_for_with ?
        locals_for_with.resimg :
        typeof resimg !== 'undefined' ? resimg : undefined, "rightColumn" in locals_for_with ?
        locals_for_with.rightColumn :
        typeof rightColumn !== 'undefined' ? rightColumn : undefined, "search" in locals_for_with ?
        locals_for_with.search :
        typeof search !== 'undefined' ? search : undefined, "serie" in locals_for_with ?
        locals_for_with.serie :
        typeof serie !== 'undefined' ? serie : undefined, "slider" in locals_for_with ?
        locals_for_with.slider :
        typeof slider !== 'undefined' ? slider : undefined, "toTextOnly" in locals_for_with ?
        locals_for_with.toTextOnly :
        typeof toTextOnly !== 'undefined' ? toTextOnly : undefined, "type" in locals_for_with ?
        locals_for_with.type :
        typeof type !== 'undefined' ? type : undefined));
    ;;return pug_html;}

      export default homeMasterRightcolumn;
    