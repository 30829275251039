
      import pug from 'pug-runtime';

      function homeSlidersSerie(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (episode, getDocumentEndpoint, getUniqueId, kind, metaperson, obsession, people, placeholderId, podcast, printDate, resimg, serie, slider, sliderId, toTextOnly) {
      kind = slider.kind;
sliderId = getUniqueId();
pug_html = pug_html + "\u003Cdiv" + (" class=\"slider my-5\""+pug.attr("id", sliderId, true, false)+pug.attr("data-une-element-area", kind, true, false)) + "\u003E\u003Cdiv class=\"d-flex align-items-start\"\u003E\u003Cinput" + (" type=\"hidden\" name=\"kind\""+pug.attr("value", kind, true, false)) + "\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"serie\""+pug.attr("value", slider.serie.id, true, false)) + "\u002F\u003E\u003Cdiv" + (" class=\"card position-relative p-4 flex-grow-1\""+pug.attr("style", pug.style("background-color: " + (kind === "PeopleObsessionSlider" ? slider.serie.color.light : "#f8f9fa") + "; border-color: " + (kind === "PeopleObsessionSlider" ? slider.serie.color.main : "#6c757d")), true, false)) + "\u003E";
if (kind === "PeopleObsessionSlider") {
pug_html = pug_html + "\u003Ch4 class=\"mb-4\"\u003ELes personnages de «&nbsp;" + (pug.escape(null == (pug_interp = slider.serie.title) ? "" : pug_interp)) + "&nbsp;»\u003C\u002Fh4\u003E";
}
else
if ((kind === "EpisodesObsessionSlider" || kind === "PodcastsProgrammeSlider")) {
pug_html = pug_html + "\u003Ch4 class=\"mb-4\"\u003E" + (pug.escape(null == (pug_interp = slider.serie.title) ? "" : pug_interp)) + "\u003C\u002Fh4\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"slider-container card-deck-wrapper\"\u003E\u003Cdiv" + (pug.attr("class", pug.classes(["js-deck g-3 row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 " + (kind === "PeopleObsessionSlider" ? "row-cols-xxl-5" : "row-cols-xxl-4") + " js-drag-and-drop-container"], [true]), false, false)+" data-drag-and-drop-direction=\"horizontal\"") + "\u003E";
// iterate slider.elements
;(function(){
  var $$obj = slider.elements;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var element = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"col\"\u003E";
switch (kind){
case "PeopleObsessionSlider":
obsession = slider.serie;
people = element;
pug_html = pug_html + "\u003Cdiv class=\"card media-card card-small\"\u003E\u003Cinput" + (" type=\"hidden\" name=\"obsession\""+pug.attr("value", obsession.id, true, false)) + "\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"document\""+pug.attr("value", people.id, true, false)) + "\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"metaperson\""+pug.attr("value", metaperson, true, false)) + "\u002F\u003E\u003Cimg" + (" class=\"card-img-top img-fluid\""+pug.attr("src", resimg(people.img, "840"), true, false)+pug.attr("alt", people.alt, true, false)) + "\u002F\u003E\u003Cdiv class=\"card-img-overlay p-2\"\u003E\u003Cdiv class=\"d-flex justify-content-between align-items-start mb-3\"\u003E\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003Ca" + (" class=\"link-light\""+pug.attr("href", getDocumentEndpoint(people), true, false)) + "\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = people.title) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003C\u002Fa\u003E\u003Cp class=\"card-text text-white\"\u003E" + (null == (pug_interp = people.subtitle) ? "" : pug_interp) + "\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
  break;
case "EpisodesObsessionSlider":
episode = element;
pug_html = pug_html + "\u003Cdiv class=\"card episode-card card-small position-relative rounded h-100\"\u003E\u003Cinput type=\"hidden\" name=\"model\" value=\"Episode\"\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"document\""+pug.attr("value", episode.id, true, false)) + "\u002F\u003E";
if (serie) {
pug_html = pug_html + "\u003Cinput" + (" type=\"hidden\" name=\"serie\""+pug.attr("value", serie, true, false)) + "\u002F\u003E";
}
if (episode.type !== "sans-image" && episode.image) {
pug_html = pug_html + "\u003Cimg" + (" class=\"card-img-top img-fluid min-height h-100\""+pug.attr("src", resimg(episode.image.files ? episode.image.files[0] : episode.image.img, "840"), true, false)+pug.attr("alt", toTextOnly(episode.image.title), true, false)) + "\u002F\u003E\u003Cdiv class=\"card-img-overlay p-2\"\u003E\u003Cdiv class=\"d-flex justify-content-between align-items-start mb-3\"\u003E\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003Cdiv class=\"btn-group\"\u003E";
if (episode.visibility === "visible") {
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-warning me-2\"\u003Evisible\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-primary\"\u003E#" + (pug.escape(null == (pug_interp = episode.number) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Ca" + (" class=\"link-light\""+pug.attr("href", getDocumentEndpoint(episode), true, false)) + "\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = toTextOnly(episode.title)) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003C\u002Fa\u003E";
if (episode.date) {
pug_html = pug_html + "\u003Cp class=\"card-text text-white\"\u003EPublié le " + (pug.escape(null == (pug_interp = printDate(episode.date)) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
}
else {
pug_html = pug_html + "\u003Cp class=\"card-text text-danger\"\u003ENon publié\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"card-body bg-info rounded min-height h-100\"\u003E\u003Cdiv class=\"d-flex justify-content-between align-items-start mb-3\"\u003E\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003Cdiv class=\"btn-group\"\u003E";
if (episode.visibility === "visible") {
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-warning me-2\"\u003Evisible\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-primary\"\u003E#" + (pug.escape(null == (pug_interp = episode.number) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
if (episode.quote) {
pug_html = pug_html + "\u003Cp class=\"mb-1 fst-italic episode-quote text-white\"\u003E" + (null == (pug_interp = episode.quote.text) ? "" : pug_interp) + "\u003C\u002Fp\u003E\u003Cp class=\"mb-3 text-white\"\u003E" + (pug.escape(null == (pug_interp = episode.quote.author) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003Ca" + (" class=\"link-light\""+pug.attr("href", getDocumentEndpoint(episode), true, false)) + "\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = toTextOnly(episode.title)) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003C\u002Fa\u003E";
if (episode.date) {
pug_html = pug_html + "\u003Cp class=\"card-text text-white\"\u003EPublié le " + (pug.escape(null == (pug_interp = printDate(episode.date)) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
}
else {
pug_html = pug_html + "\u003Cp class=\"card-text text-danger\"\u003ENon publié\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"master-alert w-100 rounded-bottom\"\u003E\u003Csmall class=\"w-100 p-1 bg-danger text-center text-white\"\u003EÉlément masqué sur desktop\u003Cbr \u002F\u003E(limite&nbsp;: 2 éléments)\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"une-alert w-100 rounded-bottom\"\u003E\u003Csmall class=\"w-100 p-1 bg-danger text-center text-white\"\u003EÉlément non visible (limite&nbsp;: 12 éléments)\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
  break;
case "PodcastsProgrammeSlider":
podcast = element;
pug_html = pug_html + "\u003Cdiv class=\"card podcast-card card-small position-relative rounded h-100\"\u003E\u003Cinput type=\"hidden\" name=\"model\" value=\"Podcast\"\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"document\""+pug.attr("value", podcast.id, true, false)) + "\u002F\u003E";
if (serie) {
pug_html = pug_html + "\u003Cinput" + (" type=\"hidden\" name=\"serie\""+pug.attr("value", serie, true, false)) + "\u002F\u003E";
}
if (podcast.image) {
pug_html = pug_html + "\u003Cimg" + (" class=\"card-img-top img-fluid min-height h-100\""+pug.attr("src", resimg(podcast.image.files ? podcast.image.files[0] : podcast.image.img, "840"), true, false)+pug.attr("alt", toTextOnly(podcast.title), true, false)) + "\u002F\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"card-body bg-info rounded-top min-height h-100\"\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"card-img-overlay p-2\"\u003E\u003Cdiv class=\"d-flex justify-content-between align-items-start mb-3\"\u003E\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003Cdiv class=\"btn-group\"\u003E";
if (podcast.visibility === "visible") {
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-warning me-2\"\u003Evisible\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-primary\"\u003E#" + (pug.escape(null == (pug_interp = podcast.number) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"d-flex\"\u003E\u003Ci class=\"d-inline fa-regular fa-circle-play fa-2x text-white me-2\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003Ca" + (" class=\"link-light mb-2\""+pug.attr("href", getDocumentEndpoint(podcast), true, false)) + "\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = toTextOnly(podcast.title)) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
if (podcast.date) {
pug_html = pug_html + "\u003Cp class=\"card-text text-white\"\u003EPublié le " + (pug.escape(null == (pug_interp = printDate(podcast.date)) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
}
else {
pug_html = pug_html + "\u003Cp class=\"card-text text-danger\"\u003ENon publié\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"master-alert w-100 rounded-bottom\"\u003E\u003Csmall class=\"w-100 p-1 bg-danger text-center text-white\"\u003EÉlément masqué sur desktop\u003Cbr \u002F\u003E(limite&nbsp;: 2 éléments)\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"une-alert w-100 rounded-bottom\"\u003E\u003Csmall class=\"w-100 p-1 bg-danger text-center text-white\"\u003EÉlément non visible (limite&nbsp;: 12 éléments)\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
  break;
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var element = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv class=\"col\"\u003E";
switch (kind){
case "PeopleObsessionSlider":
obsession = slider.serie;
people = element;
pug_html = pug_html + "\u003Cdiv class=\"card media-card card-small\"\u003E\u003Cinput" + (" type=\"hidden\" name=\"obsession\""+pug.attr("value", obsession.id, true, false)) + "\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"document\""+pug.attr("value", people.id, true, false)) + "\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"metaperson\""+pug.attr("value", metaperson, true, false)) + "\u002F\u003E\u003Cimg" + (" class=\"card-img-top img-fluid\""+pug.attr("src", resimg(people.img, "840"), true, false)+pug.attr("alt", people.alt, true, false)) + "\u002F\u003E\u003Cdiv class=\"card-img-overlay p-2\"\u003E\u003Cdiv class=\"d-flex justify-content-between align-items-start mb-3\"\u003E\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003Ca" + (" class=\"link-light\""+pug.attr("href", getDocumentEndpoint(people), true, false)) + "\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = people.title) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003C\u002Fa\u003E\u003Cp class=\"card-text text-white\"\u003E" + (null == (pug_interp = people.subtitle) ? "" : pug_interp) + "\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
  break;
case "EpisodesObsessionSlider":
episode = element;
pug_html = pug_html + "\u003Cdiv class=\"card episode-card card-small position-relative rounded h-100\"\u003E\u003Cinput type=\"hidden\" name=\"model\" value=\"Episode\"\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"document\""+pug.attr("value", episode.id, true, false)) + "\u002F\u003E";
if (serie) {
pug_html = pug_html + "\u003Cinput" + (" type=\"hidden\" name=\"serie\""+pug.attr("value", serie, true, false)) + "\u002F\u003E";
}
if (episode.type !== "sans-image" && episode.image) {
pug_html = pug_html + "\u003Cimg" + (" class=\"card-img-top img-fluid min-height h-100\""+pug.attr("src", resimg(episode.image.files ? episode.image.files[0] : episode.image.img, "840"), true, false)+pug.attr("alt", toTextOnly(episode.image.title), true, false)) + "\u002F\u003E\u003Cdiv class=\"card-img-overlay p-2\"\u003E\u003Cdiv class=\"d-flex justify-content-between align-items-start mb-3\"\u003E\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003Cdiv class=\"btn-group\"\u003E";
if (episode.visibility === "visible") {
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-warning me-2\"\u003Evisible\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-primary\"\u003E#" + (pug.escape(null == (pug_interp = episode.number) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Ca" + (" class=\"link-light\""+pug.attr("href", getDocumentEndpoint(episode), true, false)) + "\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = toTextOnly(episode.title)) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003C\u002Fa\u003E";
if (episode.date) {
pug_html = pug_html + "\u003Cp class=\"card-text text-white\"\u003EPublié le " + (pug.escape(null == (pug_interp = printDate(episode.date)) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
}
else {
pug_html = pug_html + "\u003Cp class=\"card-text text-danger\"\u003ENon publié\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"card-body bg-info rounded min-height h-100\"\u003E\u003Cdiv class=\"d-flex justify-content-between align-items-start mb-3\"\u003E\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003Cdiv class=\"btn-group\"\u003E";
if (episode.visibility === "visible") {
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-warning me-2\"\u003Evisible\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-primary\"\u003E#" + (pug.escape(null == (pug_interp = episode.number) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
if (episode.quote) {
pug_html = pug_html + "\u003Cp class=\"mb-1 fst-italic episode-quote text-white\"\u003E" + (null == (pug_interp = episode.quote.text) ? "" : pug_interp) + "\u003C\u002Fp\u003E\u003Cp class=\"mb-3 text-white\"\u003E" + (pug.escape(null == (pug_interp = episode.quote.author) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003Ca" + (" class=\"link-light\""+pug.attr("href", getDocumentEndpoint(episode), true, false)) + "\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = toTextOnly(episode.title)) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003C\u002Fa\u003E";
if (episode.date) {
pug_html = pug_html + "\u003Cp class=\"card-text text-white\"\u003EPublié le " + (pug.escape(null == (pug_interp = printDate(episode.date)) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
}
else {
pug_html = pug_html + "\u003Cp class=\"card-text text-danger\"\u003ENon publié\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"master-alert w-100 rounded-bottom\"\u003E\u003Csmall class=\"w-100 p-1 bg-danger text-center text-white\"\u003EÉlément masqué sur desktop\u003Cbr \u002F\u003E(limite&nbsp;: 2 éléments)\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"une-alert w-100 rounded-bottom\"\u003E\u003Csmall class=\"w-100 p-1 bg-danger text-center text-white\"\u003EÉlément non visible (limite&nbsp;: 12 éléments)\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
  break;
case "PodcastsProgrammeSlider":
podcast = element;
pug_html = pug_html + "\u003Cdiv class=\"card podcast-card card-small position-relative rounded h-100\"\u003E\u003Cinput type=\"hidden\" name=\"model\" value=\"Podcast\"\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"document\""+pug.attr("value", podcast.id, true, false)) + "\u002F\u003E";
if (serie) {
pug_html = pug_html + "\u003Cinput" + (" type=\"hidden\" name=\"serie\""+pug.attr("value", serie, true, false)) + "\u002F\u003E";
}
if (podcast.image) {
pug_html = pug_html + "\u003Cimg" + (" class=\"card-img-top img-fluid min-height h-100\""+pug.attr("src", resimg(podcast.image.files ? podcast.image.files[0] : podcast.image.img, "840"), true, false)+pug.attr("alt", toTextOnly(podcast.title), true, false)) + "\u002F\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"card-body bg-info rounded-top min-height h-100\"\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"card-img-overlay p-2\"\u003E\u003Cdiv class=\"d-flex justify-content-between align-items-start mb-3\"\u003E\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003Cdiv class=\"btn-group\"\u003E";
if (podcast.visibility === "visible") {
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-warning me-2\"\u003Evisible\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-primary\"\u003E#" + (pug.escape(null == (pug_interp = podcast.number) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"d-flex\"\u003E\u003Ci class=\"d-inline fa-regular fa-circle-play fa-2x text-white me-2\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003Ca" + (" class=\"link-light mb-2\""+pug.attr("href", getDocumentEndpoint(podcast), true, false)) + "\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = toTextOnly(podcast.title)) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
if (podcast.date) {
pug_html = pug_html + "\u003Cp class=\"card-text text-white\"\u003EPublié le " + (pug.escape(null == (pug_interp = printDate(podcast.date)) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
}
else {
pug_html = pug_html + "\u003Cp class=\"card-text text-danger\"\u003ENon publié\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"master-alert w-100 rounded-bottom\"\u003E\u003Csmall class=\"w-100 p-1 bg-danger text-center text-white\"\u003EÉlément masqué sur desktop\u003Cbr \u002F\u003E(limite&nbsp;: 2 éléments)\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"une-alert w-100 rounded-bottom\"\u003E\u003Csmall class=\"w-100 p-1 bg-danger text-center text-white\"\u003EÉlément non visible (limite&nbsp;: 12 éléments)\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
  break;
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"btn-group-vertical rounded-end\"\u003E\u003Cbutton" + (" class=\"btn btn-danger rounded-0\""+" type=\"button\""+pug.attr("data-une-element-id", sliderId, true, false)) + "\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003Cbutton" + (" class=\"btn btn-success btn-edit text-light rounded-0\""+" type=\"button\" data-bs-toggle=\"modal\" data-bs-target=\"#add-une-element\""+pug.attr("data-une-element-id", sliderId, true, false)+pug.attr("data-une-element-document", slider.serie.id, true, false)+pug.attr("data-une-element-endpoint", kind === "PodcastsProgrammeSlider" ? "programmes" : "obsessions", true, false)+" data-une-element-kind=\"une-serie-slider\""+pug.attr("data-une-element-children", kind === "PeopleObsessionSlider" ? "elements-people" : "elements-articles", true, false)+pug.attr("data-une-element-length", slider.elements.length, true, false)) + "\u003E\u003Ci class=\"fa-solid fa-pen-to-square text-white\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003Cbutton" + (" class=\"btn btn-primary btn-arrow text-light rounded-0\""+" type=\"button\" data-arrow-direction=\"up\""+pug.attr("data-une-element-id", sliderId, true, false)) + "\u003E\u003Ci class=\"fa-solid fa-arrow-up text-white\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003Cbutton" + (" class=\"btn btn-primary btn-arrow text-light rounded-0\""+" type=\"button\" data-arrow-direction=\"down\""+pug.attr("data-une-element-id", sliderId, true, false)) + "\u003E\u003Ci class=\"fa-solid fa-arrow-down text-white\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"card-deck-wrapper my-5 d-flex justify-content-center\"\u003E";
placeholderId = sliderId;
pug_html = pug_html + "\u003Cdiv" + (" class=\"btn add-button w-auto border-0\""+" data-bs-toggle=\"modal\" data-bs-target=\"#add-une-element\""+pug.attr("data-placeholder-id", placeholderId, true, false)) + "\u003E\u003Ci class=\"fa-solid fa-circle-plus fa-3x text-primary\"\u003E\u003C\u002Fi\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "episode" in locals_for_with ?
        locals_for_with.episode :
        typeof episode !== 'undefined' ? episode : undefined, "getDocumentEndpoint" in locals_for_with ?
        locals_for_with.getDocumentEndpoint :
        typeof getDocumentEndpoint !== 'undefined' ? getDocumentEndpoint : undefined, "getUniqueId" in locals_for_with ?
        locals_for_with.getUniqueId :
        typeof getUniqueId !== 'undefined' ? getUniqueId : undefined, "kind" in locals_for_with ?
        locals_for_with.kind :
        typeof kind !== 'undefined' ? kind : undefined, "metaperson" in locals_for_with ?
        locals_for_with.metaperson :
        typeof metaperson !== 'undefined' ? metaperson : undefined, "obsession" in locals_for_with ?
        locals_for_with.obsession :
        typeof obsession !== 'undefined' ? obsession : undefined, "people" in locals_for_with ?
        locals_for_with.people :
        typeof people !== 'undefined' ? people : undefined, "placeholderId" in locals_for_with ?
        locals_for_with.placeholderId :
        typeof placeholderId !== 'undefined' ? placeholderId : undefined, "podcast" in locals_for_with ?
        locals_for_with.podcast :
        typeof podcast !== 'undefined' ? podcast : undefined, "printDate" in locals_for_with ?
        locals_for_with.printDate :
        typeof printDate !== 'undefined' ? printDate : undefined, "resimg" in locals_for_with ?
        locals_for_with.resimg :
        typeof resimg !== 'undefined' ? resimg : undefined, "serie" in locals_for_with ?
        locals_for_with.serie :
        typeof serie !== 'undefined' ? serie : undefined, "slider" in locals_for_with ?
        locals_for_with.slider :
        typeof slider !== 'undefined' ? slider : undefined, "sliderId" in locals_for_with ?
        locals_for_with.sliderId :
        typeof sliderId !== 'undefined' ? sliderId : undefined, "toTextOnly" in locals_for_with ?
        locals_for_with.toTextOnly :
        typeof toTextOnly !== 'undefined' ? toTextOnly : undefined));
    ;;return pug_html;}

      export default homeSlidersSerie;
    