
      import pug from 'pug-runtime';

      function homeCardsPeople(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (getDocumentEndpoint, metaperson, obsession, people, resimg) {
      pug_html = pug_html + "\u003Cdiv class=\"card media-card card-small\"\u003E\u003Cinput" + (" type=\"hidden\" name=\"obsession\""+pug.attr("value", obsession.id, true, false)) + "\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"document\""+pug.attr("value", people.id, true, false)) + "\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"metaperson\""+pug.attr("value", metaperson, true, false)) + "\u002F\u003E\u003Cimg" + (" class=\"card-img-top img-fluid\""+pug.attr("src", resimg(people.img, "840"), true, false)+pug.attr("alt", people.alt, true, false)) + "\u002F\u003E\u003Cdiv class=\"card-img-overlay p-2\"\u003E\u003Cdiv class=\"d-flex justify-content-between align-items-start mb-3\"\u003E\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003Ca" + (" class=\"link-light\""+pug.attr("href", getDocumentEndpoint(people), true, false)) + "\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = people.title) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003C\u002Fa\u003E\u003Cp class=\"card-text text-white\"\u003E" + (null == (pug_interp = people.subtitle) ? "" : pug_interp) + "\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "getDocumentEndpoint" in locals_for_with ?
        locals_for_with.getDocumentEndpoint :
        typeof getDocumentEndpoint !== 'undefined' ? getDocumentEndpoint : undefined, "metaperson" in locals_for_with ?
        locals_for_with.metaperson :
        typeof metaperson !== 'undefined' ? metaperson : undefined, "obsession" in locals_for_with ?
        locals_for_with.obsession :
        typeof obsession !== 'undefined' ? obsession : undefined, "people" in locals_for_with ?
        locals_for_with.people :
        typeof people !== 'undefined' ? people : undefined, "resimg" in locals_for_with ?
        locals_for_with.resimg :
        typeof resimg !== 'undefined' ? resimg : undefined));
    ;;return pug_html;}

      export default homeCardsPeople;
    