
      import pug from 'pug-runtime';

      function homeCardsPodcast(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (getDocumentEndpoint, podcast, printDate, resimg, serie, toTextOnly) {
      pug_html = pug_html + "\u003Cdiv class=\"card podcast-card card-small position-relative rounded h-100\"\u003E\u003Cinput type=\"hidden\" name=\"model\" value=\"Podcast\"\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"document\""+pug.attr("value", podcast.id, true, false)) + "\u002F\u003E";
if (serie) {
pug_html = pug_html + "\u003Cinput" + (" type=\"hidden\" name=\"serie\""+pug.attr("value", serie, true, false)) + "\u002F\u003E";
}
if (podcast.image) {
pug_html = pug_html + "\u003Cimg" + (" class=\"card-img-top img-fluid min-height h-100\""+pug.attr("src", resimg(podcast.image.files ? podcast.image.files[0] : podcast.image.img, "840"), true, false)+pug.attr("alt", toTextOnly(podcast.title), true, false)) + "\u002F\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"card-body bg-info rounded-top min-height h-100\"\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"card-img-overlay p-2\"\u003E\u003Cdiv class=\"d-flex justify-content-between align-items-start mb-3\"\u003E\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003Cdiv class=\"btn-group\"\u003E";
if (podcast.visibility === "visible") {
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-warning me-2\"\u003Evisible\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-primary\"\u003E#" + (pug.escape(null == (pug_interp = podcast.number) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"d-flex\"\u003E\u003Ci class=\"d-inline fa-regular fa-circle-play fa-2x text-white me-2\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003Ca" + (" class=\"link-light mb-2\""+pug.attr("href", getDocumentEndpoint(podcast), true, false)) + "\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = toTextOnly(podcast.title)) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E";
if (podcast.date) {
pug_html = pug_html + "\u003Cp class=\"card-text text-white\"\u003EPublié le " + (pug.escape(null == (pug_interp = printDate(podcast.date)) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
}
else {
pug_html = pug_html + "\u003Cp class=\"card-text text-danger\"\u003ENon publié\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"master-alert w-100 rounded-bottom\"\u003E\u003Csmall class=\"w-100 p-1 bg-danger text-center text-white\"\u003EÉlément masqué sur desktop\u003Cbr \u002F\u003E(limite&nbsp;: 2 éléments)\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"une-alert w-100 rounded-bottom\"\u003E\u003Csmall class=\"w-100 p-1 bg-danger text-center text-white\"\u003EÉlément non visible (limite&nbsp;: 12 éléments)\u003C\u002Fsmall\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "getDocumentEndpoint" in locals_for_with ?
        locals_for_with.getDocumentEndpoint :
        typeof getDocumentEndpoint !== 'undefined' ? getDocumentEndpoint : undefined, "podcast" in locals_for_with ?
        locals_for_with.podcast :
        typeof podcast !== 'undefined' ? podcast : undefined, "printDate" in locals_for_with ?
        locals_for_with.printDate :
        typeof printDate !== 'undefined' ? printDate : undefined, "resimg" in locals_for_with ?
        locals_for_with.resimg :
        typeof resimg !== 'undefined' ? resimg : undefined, "serie" in locals_for_with ?
        locals_for_with.serie :
        typeof serie !== 'undefined' ? serie : undefined, "toTextOnly" in locals_for_with ?
        locals_for_with.toTextOnly :
        typeof toTextOnly !== 'undefined' ? toTextOnly : undefined));
    ;;return pug_html;}

      export default homeCardsPodcast;
    