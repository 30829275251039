import $ from "jquery";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

// ///////////////////////////////////////////////////////////////////////////////////
// REQUIRE PUG TEMPLATES                                                            //
// ///////////////////////////////////////////////////////////////////////////////////
// Templates Holder
const templates = {};

// Import all function
function importAll(r) {
  r.keys().forEach((template) => {
    templates[template.replace(/(^\.[/\\]|\.js$)/g, "")] = r(template).default;
  });
}

// Require libraries
importAll(require.context("../templates/", true, /\.js$/));

// ///////////////////////////////////////////////////////////////////////////////////
// INITIALIZE THE PLUGIN                                                            //
// ///////////////////////////////////////////////////////////////////////////////////
function createTemplatesLocals() {
  // Variables
  const appLocals = {};
  // For Unescape
  const unescapeMap = {
    "&amp;": "&",
    "&lt;": "<",
    "&gt;": ">",
    "&quot;": '"',
    "&#x27;": "'",
    "&#x60;": "`",
    "&nbsp;": " ",
  };
  const unescapeEscaper = function (match) {
    return unescapeMap[match];
  };
  const unescapeRegexp = /(?:&amp;|&lt;|&gt;|&quot;|&#x27;|&#x60;|&nbsp;)/g;
  // For BR Replacement
  const brRegexp = /<\/?br\s?\/?>/g;
  // For Links Replacement
  const linkRegexp = /(?:<\/?a\b[^>]*>|<!--[\s\S]*?-->)/gi;
  // For Endpoints
  const kModelNameEndpointMatching = Object.freeze({
    Author: "/medias/author",
    Color: "/couleurs",
    Data: "/medias/data",
    Document: "/medias/document",
    Episode: "/episodes",
    ClassicEpisode: "/episodes",
    InfographyEpisode: "/episodes",
    StoryEpisode: "/episodes",
    ASideBSideEpisode: "/episodes",
    LiveEpisode: "/episodes",
    Home: "/home",
    Image: "/medias/image",
    PhotoImage: "/medias/photoimage",
    SlideshowImage: "/medias/slideshowimage",
    ComparisonImage: "/medias/comparisonimage",
    InThePress: "/site/danslapresse",
    MetaPerson: "/metapersons",
    Newsletter: "/newsletters",
    Notification: null,
    Obsession: "/obsessions",
    Parameter: null,
    People: "/medias/people",
    Place: "/medias/place",
    Podcast: "/podcasts",
    LesJoursPodcast: "/podcasts",
    LesCroissantsPodcast: "/podcasts",
    Programme: "/programmes",
    Search: "/site/recherches",
    Serie: "/series",
    Soundtrack: "/medias/soundtrack",
    PlaylistSoundtrack: "/medias/playlistsoundtrack",
    MusicSoundtrack: "/medias/musicsoundtrack",
    Special: "/marketing/special",
    Tag: "/tags",
    Upload: "/marketing/document",
    User: "/utilisateurs",
    Video: "/medias/video",
    Website: "/sitesweb",
  });
  const kSerieKeyEndpointMatching = Object.freeze({
    people: "/medias/people",
    images: "/medias/image",
    data: "/medias/data",
    places: "/medias/place",
    videos: "/medias/video",
    soundtrack: "/medias/soundtrack",
    documents: "/medias/document",
  });

  // Function to Remove BR Tags in the String
  appLocals.toTextOnly = function (string) {
    // Check if there is a String
    if (string === undefined) {
      return "";
    }

    // Convert the String
    return (
      string
        // Remove BRs
        .replace(brRegexp, " ")
        // Remove Links
        .replace(linkRegexp, "")
        .replace(/\s+/gi, " ")
        // Unescape String
        .replace(unescapeRegexp, unescapeEscaper)
    );
  };

  // Function to Print a Date in a Human Readable Format
  appLocals.printDate = function (date, noTime) {
    // Check if there is a Date
    if (date === undefined) {
      return "";
    }

    // Check if the Date is a String
    if (typeof date === "string") {
      date = new Date(date);
    }

    // Get Date Elements
    let mn = date.getMinutes();
    let hh = date.getHours();
    let d = date.getDate();
    let m = date.getMonth() + 1;
    const y = date.getFullYear();

    // Convert Values to 2 Digits Notation
    if (mn < 10) {
      mn = "0" + mn;
    }
    if (hh < 10) {
      hh = "0" + hh;
    }
    if (d < 10) {
      d = "0" + d;
    }
    if (m < 10) {
      m = "0" + m;
    }

    // Return the Human Readable Date
    if (noTime === true) {
      return d + "/" + m + "/" + y;
    } else {
      return d + "/" + m + "/" + y + " à " + hh + "h" + mn;
    }
  };

  // Format a Date
  appLocals.formatdate = function (date, locale, format) {
    // Check if there is a Date
    if (date === undefined) {
      // Return the Current Date with the Default Locale & Format
      return moment().format();
    }

    // Create the Moment Object from the Given Date
    const mDate = moment(date);

    // Set the Required Locale
    if (locale !== undefined) {
      mDate.locale(locale);
    }

    // Return the Formated Date
    return mDate.format(format);
  };

  // Add a Function to Show a Resized Version of the Image
  appLocals.resimg = function (image, res) {
    // Return the Responsive Version of the Image
    return image === undefined ? "" : image.replace(/^\/?ressources/, "/ressources/res" + res);
  };

  // Function to Remove Any Non-Alphanumeric Chars in the String
  appLocals.toAlphanumeric = function (string) {
    // Return the string without any non-alphanumeric chars
    return string.replace(/[^a-zA-Z0-9]/g, "");
  };

  // Endpoints Getters
  appLocals.getEndpointForModelName = function (modelName) {
    return kModelNameEndpointMatching[modelName];
  };
  appLocals.getEndpointForSerieKey = function (serieKey) {
    return kSerieKeyEndpointMatching[serieKey];
  };
  appLocals.getDocumentEndpoint = function (document) {
    return `${appLocals.getEndpointForModelName(document.modelName)}/${document.id}`;
  };
  appLocals.getModelEndpoint = function (document) {
    return appLocals.getEndpointForModelName(document.modelName);
  };

  // Get an UUID
  appLocals.getUniqueId = function () {
    return uuidv4();
  };

  // Clone and sort an Array of Notes/Minis by a given field
  appLocals.sortMinisNotesByDocField = function (array, field) {
    const sorted = [...array];
    return sorted.sort((a, b) => {
      const aField = (a.doc ?? a)?.[field]?.toLowerCase();
      const bField = (b.doc ?? b)?.[field]?.toLowerCase();

      if (aField === bField) {
        return 0;
      }
      return bField === undefined || aField < bField ? -1 : 1;
    });
  };

  // Return the created object
  return appLocals;
}

// Create Locals
const locals = createTemplatesLocals();

// Create the Render Template Function
$.renderTemplate = function (templateName, data) {
  if (templates[templateName] === undefined) {
    throw new Error("Unknown Template : " + templateName);
  }

  return templates[templateName]($.extend({}, locals, data));
};

// Add Render Utils into jQuery
$.renderUtils = locals;
