
      import pug from 'pug-runtime';

      function homeCoversPodcast(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (cover, coverId, getDocumentEndpoint, getUniqueId, placeholderId, printDate, toTextOnly) {
      coverId = getUniqueId();
pug_html = pug_html + "\u003Cdiv" + (" class=\"cover my-5\""+pug.attr("id", coverId, true, false)+" data-une-element-area=\"CoverPodcast\"") + "\u003E\u003Cdiv class=\"d-flex align-items-start\"\u003E\u003Cinput type=\"hidden\" name=\"kind\" value=\"CoverPodcast\"\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"cover\""+pug.attr("value", cover.id, true, false)) + "\u002F\u003E\u003Cdiv class=\"card card-episode card-tall border-0 w-100\"\u003E";
if (cover.image) {
pug_html = pug_html + "\u003Cimg" + (" class=\"img-fluid min-height\""+pug.attr("src", cover.image.files ? cover.image.files[0] : cover.image.img, true, false)+pug.attr("alt", toTextOnly(cover.title), true, false)) + "\u002F\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"card-body bg-info min-height\"\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cdiv class=\"card-img-overlay p-4\"\u003E\u003Cdiv class=\"d-flex justify-content-between align-items-start mb-3\"\u003E\u003Cdiv class=\"search dropdown w-100 me-4\"\u003E\u003Cdiv class=\"input-group\"\u003E\u003Cinput class=\"form-control js-autocomplete\" type=\"text\" placeholder=\"Modifier l'élément\" data-endpoint=\"[&quot;\u002Fepisodes&quot;, &quot;\u002Fpodcasts&quot;]\" autocomplete=\"off\"\u002F\u003E\u003Cspan class=\"input-group-text\"\u003E\u003Ci class=\"fa-solid fa-magnifying-glass\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"dropdown-menu dropdown-menu-w100\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"btn-group\"\u003E";
if (cover.visibility === "visible") {
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-warning me-2\"\u003Evisible\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003Cspan class=\"badge rounded-pill bg-primary\"\u003E#" + (pug.escape(null == (pug_interp = cover.number) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"d-flex\"\u003E\u003Ci class=\"d-inline fa-regular fa-circle-play fa-2x text-white me-2\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003Ca" + (" class=\"d-inline link-light\""+pug.attr("href", getDocumentEndpoint(cover), true, false)) + "\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = toTextOnly(cover.title)) ? "" : pug_interp) + "\u003C\u002Fh4\u003E";
if (cover.date) {
pug_html = pug_html + "\u003Cp class=\"card-text text-white\"\u003EPublié le " + (pug.escape(null == (pug_interp = printDate(cover.date)) ? "" : pug_interp)) + "\u003C\u002Fp\u003E";
}
else {
pug_html = pug_html + "\u003Cp class=\"card-text text-danger\"\u003ENon publié\u003C\u002Fp\u003E";
}
pug_html = pug_html + "\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"btn-group-vertical rounded-end\"\u003E\u003Cbutton" + (" class=\"btn btn-danger rounded-0\""+" type=\"button\""+pug.attr("data-une-element-id", coverId, true, false)) + "\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003Cbutton" + (" class=\"btn btn-success btn-edit text-light rounded-0\""+" type=\"button\" data-bs-toggle=\"modal\" data-bs-target=\"#add-une-element\""+pug.attr("data-une-element-id", coverId, true, false)+pug.attr("data-une-element-document", cover.id, true, false)+" data-une-element-kind=\"une-cover-element\" data-une-element-endpoint=\"podcasts\"") + "\u003E\u003Ci class=\"fa-solid fa-pen-to-square text-white\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003Cbutton" + (" class=\"btn btn-primary btn-arrow text-light rounded-0\""+" type=\"button\" data-arrow-direction=\"up\""+pug.attr("data-une-element-id", coverId, true, false)) + "\u003E\u003Ci class=\"fa-solid fa-arrow-up text-white\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003Cbutton" + (" class=\"btn btn-primary btn-arrow text-light rounded-0\""+" type=\"button\" data-arrow-direction=\"down\""+pug.attr("data-une-element-id", coverId, true, false)) + "\u003E\u003Ci class=\"fa-solid fa-arrow-down text-white\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"card-deck-wrapper my-5 d-flex justify-content-center\"\u003E";
placeholderId = coverId;
pug_html = pug_html + "\u003Cdiv" + (" class=\"btn add-button w-auto border-0\""+" data-bs-toggle=\"modal\" data-bs-target=\"#add-une-element\""+pug.attr("data-placeholder-id", placeholderId, true, false)) + "\u003E\u003Ci class=\"fa-solid fa-circle-plus fa-3x text-primary\"\u003E\u003C\u002Fi\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "cover" in locals_for_with ?
        locals_for_with.cover :
        typeof cover !== 'undefined' ? cover : undefined, "coverId" in locals_for_with ?
        locals_for_with.coverId :
        typeof coverId !== 'undefined' ? coverId : undefined, "getDocumentEndpoint" in locals_for_with ?
        locals_for_with.getDocumentEndpoint :
        typeof getDocumentEndpoint !== 'undefined' ? getDocumentEndpoint : undefined, "getUniqueId" in locals_for_with ?
        locals_for_with.getUniqueId :
        typeof getUniqueId !== 'undefined' ? getUniqueId : undefined, "placeholderId" in locals_for_with ?
        locals_for_with.placeholderId :
        typeof placeholderId !== 'undefined' ? placeholderId : undefined, "printDate" in locals_for_with ?
        locals_for_with.printDate :
        typeof printDate !== 'undefined' ? printDate : undefined, "toTextOnly" in locals_for_with ?
        locals_for_with.toTextOnly :
        typeof toTextOnly !== 'undefined' ? toTextOnly : undefined));
    ;;return pug_html;}

      export default homeCoversPodcast;
    