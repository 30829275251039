
      import pug from 'pug-runtime';

      function homeCardsMedia(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (endpoint, getDocumentEndpoint, getMIMEType, media, resimg, search, slider) {
      pug_html = pug_html + "\u003Cdiv class=\"card media-card card-small rounded\"\u003E\u003Cinput" + (" type=\"hidden\" name=\"model\""+pug.attr("value", media.modelName, true, false)) + "\u002F\u003E\u003Cinput" + (" type=\"hidden\" name=\"document\""+pug.attr("value", media._id, true, false)) + "\u002F\u003E";
if (media.img || (media.modelName === "SlideshowImage") || (media.modelName === "ComparisonImage")) {
pug_html = pug_html + "\u003Cimg" + (" class=\"card-img-top img-fluid rounded-top\""+pug.attr("src", resimg(media.img ? media.img : media.files[0], "840"), true, false)+pug.attr("alt", media.alt, true, false)) + "\u002F\u003E\u003Cdiv class=\"card-img-overlay p-2\"\u003E";
if (search) {
pug_html = pug_html + "\u003Cdiv class=\"search top-0 dropdown w-100\" role=\"group\"\u003E\u003Cdiv class=\"input-group\"\u003E\u003Cinput" + (" class=\"form-control js-autocomplete\""+" type=\"text\" placeholder=\"Modifier le média à la une\""+pug.attr("data-endpoint", endpoint ? endpoint : '["/obsessions", "/programmes"]', true, false)+" autocomplete=\"off\"") + "\u002F\u003E\u003Cspan class=\"input-group-text\"\u003E\u003Ci class=\"fa-solid fa-magnifying-glass\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"dropdown-menu dropdown-menu-w100\"\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"d-flex justify-content-between align-items-start mb-3\"\u003E";
if (!(slider && slider.obsession)) {
pug_html = pug_html + "\u003Cbutton class=\"btn btn-danger rounded-circle\" type=\"button\"\u003E\u003Ci class=\"fa-solid fa-xmark\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E\u003C\u002Fbutton\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Ca" + (" class=\"link-light\""+pug.attr("href", getDocumentEndpoint(media), true, false)) + "\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = media.title) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003C\u002Fa\u003E\u003Cp class=\"card-text text-white\"\u003E" + (null == (pug_interp = media.subtitle) ? "" : pug_interp) + "\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E";
}
else
if (media.files) {
pug_html = pug_html + "\u003Cdiv class=\"card-body bg-info\"\u003E";
if (media.modelName === "MusicSoundtrack") {
pug_html = pug_html + "\u003Cfigure class=\"mt-2\"\u003E\u003Caudio class=\"w-100\" controls=\"true\" preload=\"none\"\u003E";
// iterate media.files
;(function(){
  var $$obj = media.files;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var audio = $$obj[pug_index0];
pug_html = pug_html + "\u003Csource" + (pug.attr("src", audio, true, false)+pug.attr("type", getMIMEType(audio), true, false)) + "\u002F\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var audio = $$obj[pug_index0];
pug_html = pug_html + "\u003Csource" + (pug.attr("src", audio, true, false)+pug.attr("type", getMIMEType(audio), true, false)) + "\u002F\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Faudio\u003E\u003C\u002Ffigure\u003E";
}
else
if (media.modelName === "Document") {
pug_html = pug_html + "\u003Cdiv class=\"mt-2\"\u003E";
// iterate media.files
;(function(){
  var $$obj = media.files;
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var doc = $$obj[pug_index1];
pug_html = pug_html + "\u003Ca" + (pug.attr("href", doc, true, false)+" target=\"_blank\"") + "\u003E\u003Ci class=\"fa-solid fa-file\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E&nbsp;&nbsp;" + (pug.escape(null == (pug_interp = doc.replace("/ressources/document/", "")) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var doc = $$obj[pug_index1];
pug_html = pug_html + "\u003Ca" + (pug.attr("href", doc, true, false)+" target=\"_blank\"") + "\u003E\u003Ci class=\"fa-solid fa-file\" aria-hidden=\"true\"\u003E\u003C\u002Fi\u003E&nbsp;&nbsp;" + (pug.escape(null == (pug_interp = doc.replace("/ressources/document/", "")) ? "" : pug_interp)) + "\u003C\u002Fa\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Ca" + (" class=\"link-light\""+pug.attr("href", getDocumentEndpoint(media), true, false)) + "\u003E\u003Ch4 class=\"card-title text-white\"\u003E" + (null == (pug_interp = media.title) ? "" : pug_interp) + "\u003C\u002Fh4\u003E\u003C\u002Fa\u003E\u003Cp class=\"card-text text-white\"\u003E" + (null == (pug_interp = media.subtitle) ? "" : pug_interp) + "\u003C\u002Fp\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
    }.call(this, "endpoint" in locals_for_with ?
        locals_for_with.endpoint :
        typeof endpoint !== 'undefined' ? endpoint : undefined, "getDocumentEndpoint" in locals_for_with ?
        locals_for_with.getDocumentEndpoint :
        typeof getDocumentEndpoint !== 'undefined' ? getDocumentEndpoint : undefined, "getMIMEType" in locals_for_with ?
        locals_for_with.getMIMEType :
        typeof getMIMEType !== 'undefined' ? getMIMEType : undefined, "media" in locals_for_with ?
        locals_for_with.media :
        typeof media !== 'undefined' ? media : undefined, "resimg" in locals_for_with ?
        locals_for_with.resimg :
        typeof resimg !== 'undefined' ? resimg : undefined, "search" in locals_for_with ?
        locals_for_with.search :
        typeof search !== 'undefined' ? search : undefined, "slider" in locals_for_with ?
        locals_for_with.slider :
        typeof slider !== 'undefined' ? slider : undefined));
    ;;return pug_html;}

      export default homeCardsMedia;
    